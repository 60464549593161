<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">EDIT MEDIA</p>
    </div>
    <div class="dialog-content" style="overflow-y: auto; max-height: 560px;">
      <b-container class="mt-3" fluid>
        <b-form @submit="onSubmit">
          <b-form-group label="Title: " label-for="mediaTitle" :label-cols="2">
            <b-form-input id="mediaTitle"
                          type="text"
                          aria-describedby="mediaTitle-input"
                          placeholder="Enter Media Title"
                          autocomplete="off"
                          @input="$v.form.mediaTitle.$touch()"
                          v-model.trim="$v.form.mediaTitle.$model"
                          :state="!$v.form.mediaTitle.$error">
            </b-form-input>
            <b-form-invalid-feedback id="mediaTitle-input">
              Title is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      type="submit"
                      variant="outline-primary"
                      :disabled="$v.form.$invalid">
              Submit
            </b-button>
            <b-button class="text-center ml-1"
                      variant="outline-danger"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-container>
    </div>
  </div>
</template>
<script>
  import { required } from 'vuelidate/lib/validators';

  export default {
    props : {
      data : Boolean,
    },
    data() {
      return {
        form : {
          mediaTitle : this.data.media_title,
        },
      };
    },
    validations : {
      form : { mediaTitle : { required } },
    },
    methods : {

      /**
       * Submit Form
       * @param e
       */
      onSubmit(e) {
        e.preventDefault();
        this.$http.put('api/media', {
          'mediaId'      : this.data.media_id,
          'updateFields' : {
            'media_title' : this.form.mediaTitle,
          },
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Media updated successfully.',
          });
          this.closeModal();
        }).catch(err => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : err.response.data.msg,
          });
          this.closeModal();
        });

      },

      /**
       * Close Modal
       */
      closeModal() {
        this.$emit('modalClose');
        this.$emit('close');
      },
    },
    mounted() {
    },
  };
</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/components/admin/media/edit-media';
</style>
